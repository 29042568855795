import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./404.scss"

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found" />
		<div className="notfound">
			<h1>Not Found</h1>
			<p className="notfound__subtitle">You&#39;re looking for something that doesn&#39;t exist...</p>
			<a className="notfound__button" href="/">Go home</a>
		</div>
	</Layout>
)

export default NotFoundPage
